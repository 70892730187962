<template>
  <v-dialog
    persistent
    :max-width="width"
    :scrollable="scrollable"
    :value="show"
  >
    <v-card class="mx-auto">
      <v-toolbar
        :class="[
          { 'blue white--text': tag === 'add' },
          { 'green white--text': tag === 'activate' },
          { 'red darken-1 white--text': tag === 'delete' },
          { 'yellow darken-3 white--text': tag === 'edit' }
        ]"
        elevation="1"
        dense
      >
        <v-toolbar-title>{{ title }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn
          color="white"
          icon
          class="hidden-xs-only"
          @click="fecharDialog()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <div class="subtitle-1 mt-2">
          <slot name="body">
            <StepperCadastroColaborador
              @concluirCadastro="fecharDialog()"
            ></StepperCadastroColaborador>
          </slot>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import ButtonMain from '../../atoms/buttons/ButtonMain.vue';
  import StepperCadastroColaborador from '../../../components/organisms/StepperCadastroColaborador.vue';

  export default {
    props: {
      show: Boolean,
      desabilitarConfirmacao: Boolean,
      width: {
        type: Number,
        default: 660
      },
      tag: String,
      title: String,
      primary: Boolean,
      secondary: Boolean,
      warning: Boolean,
      success: Boolean,
      scrollable: Boolean,
      persistente: Boolean,
    },

    components: {
      ButtonMain,
      StepperCadastroColaborador
    },

    methods: {
      fecharDialog() {
        const estadoCadastro = sessionStorage.getItem('estado_cadastro_colaborador');

        if(estadoCadastro && estadoCadastro === 'incompleto') {
          this.$root.vtoast.show({
            status: 'warning',
            title: 'Atenção!',
            text: 'Cadastro ainda não concluído.',
            time: 30000,
          });

          return;
        }

        sessionStorage.removeItem('cadastro_colaborador');
        sessionStorage.removeItem('estado_cadastro_colaborador');
        this.$emit('close');
      }
    }
  }
</script>

<style>
.footer-dialog {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 1rem 3rem;
}
</style>
