<template>
  <div>
    <v-form class="form-fields fields">
      <TitleH2 class="mt-1 mb-5 mx-3" title="Dados Pessoais" />

      <FormField
        ref="formCadastrarFilail"
        :validate="keyFormValidacao"
        :fields="fields"
      />
    </v-form>

    <div class="footer-cadastro">
      <ButtonMain
        class="primary"
        style="width: 15rem"
        :animacaoIconeFinal="realizandoAcao ? 'spin' : ''"
        :iconeFinal="realizandoAcao ? 'autorenew' : ''"
        :text="realizandoAcao ? 'Salvando...' : 'Salvar e Continuar'"
        :desabilitar="realizandoAcao"
        @click.native="avancarPasso()"
      ></ButtonMain>
    </div>
  </div>
</template>

<script>
import TitleH2 from '../../components/atoms/title/TitleH2.vue'
import FormField from '../../components/atoms/forms/FormField.vue'
import ButtonMain from '../../components/atoms/buttons/ButtonMain.vue'
import cepService from '../../services/CepService'
import utilService from '../../services/UtilService'
import colaboradorService from '../../services/ColaboradorService'

export default {
  components: {
    TitleH2,
    FormField,
    ButtonMain
  },

  data() {
    return {
      keyFormValidacao: 0,
      mensagemErroPadrao: 'Falha na conexão. Por favor, tente mais tarde.',
      realizandoAcao: false,
      itemsPerfis: [],
      listaUf: [],
      validadores: {
        obrigatorio: 'Campo obrigatório',
        invalido: 'Campo inválido',
        numeroConselho: 'Campo obrigatório quando o Conselho for selecionado'
      },
      fields: {
        nome: {
          label: 'Nome',
          valueField: null,
          value: '',
          cols: 12,
          field: 'InputField',
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        cpf: {
          label: 'CPF',
          valueField: null,
          cols: 3,
          value: '',
          field: 'InputMaskField',
          maskFormat: '###.###.###-##',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) =>
              value.replace(/[^\d]/g, '').length >= 11 ||
              this.validadores.invalido
          ]
        },
        dataNascimento: {
          label: 'Data de Nascimento',
          valueField: null,
          cols: 3,
          value: '',
          field: 'InputMaskField',
          maskFormat: '##/##/####',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) =>
              value.replace(/[^\d]/g, '').length >= 8 ||
              this.validadores.invalido
          ]
        },
        ufNaturalidade: {
          label: 'UF Naturalidade',
          valueField: null,
          value: '',
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'sigla',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        cidadeNaturalidade: {
          label: 'Cidade Naturalidade',
          valueField: null,
          value: null,
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'nome',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        profissao: {
          label: 'Profissão',
          valueField: null,
          value: null,
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'descricao',
          clearable: true,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        conselhoClasse: {
          label: 'Conselho Classe',
          valueField: null,
          value: null,
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'sigla',
          clearable: true,
          disabled: true,
          rules: []
        },
        numeroConselho: {
          label: 'Número do Conselho',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputField',
          disabled: true,
          rules: [],
          key: 19
        },

        contatosDivisor: {
          cols: 12,
          label: 'Contatos',
          field: 'DividerMain'
        },
        email: {
          label: 'Email',
          valueField: null,
          value: '',
          cols: 7,
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) =>
              /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) ||
              this.validadores.invalido
          ],
          field: 'InputField'
        },
        ddd: {
          label: 'DDD',
          valueField: null,
          cols: 2,
          value: '',

          field: 'InputMaskField',
          maskFormat: '##',
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        telefone: {
          label: 'Telefone ',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputMaskField',
          maskFormat: '#####-####',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) =>
              value.replace(/[^\d]/g, '').length >= 9 ||
              this.validadores.invalido
          ]
        },

        enderecoDivisor: {
          cols: 12,
          label: 'Endereço',
          field: 'DividerMain'
        },
        cep: {
          label: 'CEP',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputMaskField',
          maskFormat: '##.###-###',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) =>
              value.replace(/[^\d]/g, '').length >= 8 ||
              this.validadores.invalido
          ]
        },
        logradouro: {
          label: 'Logradouro',
          value: '',
          valueField: null,
          cols: 6,
          rules: [(value) => !!value || this.validadores.obrigatorio],
          field: 'InputField'
        },
        numero: {
          label: 'Número',
          valueField: null,
          value: '',
          cols: 3,
          rules: [(value) => !!value || this.validadores.obrigatorio],
          field: 'InputField'
        },
        complemento: {
          label: 'Complemento',
          valueField: null,
          value: null,
          cols: 6,
          field: 'InputField'
        },
        bairro: {
          label: 'Bairro',
          valueField: null,
          value: '',
          cols: 6,
          field: 'InputField',
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        ufEndereco: {
          label: 'UF',
          valueField: null,
          value: '',
          cols: 2,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'sigla',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        cidadeEndereco: {
          label: 'Cidade',
          valueField: null,
          value: '',
          cols: 4,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'nome',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio]
        }
      },
      enderecoCep: [],
      itensPorPagina: 10
    }
  },

  created() {
    this.receberUFs()
    this.receberConselhosClasse()
    this.receberProfissoes()
  },

  methods: {
    validarCamposFormulario(campos) {
      let campoInvalido = false

      for (const [key, value] of Object.entries(campos)) {
        const valorCampo = value.value
        const regras = value.rules
        let erroCampo = false

        if (regras && regras.length) {
          for (const regra of regras) {
            if (
              regra(valorCampo) === this.validadores.obrigatorio ||
              regra(valorCampo) === this.validadores.invalido ||
              regra(valorCampo) === this.validadores.numeroConselho
            ) {
              erroCampo = true
              break
            }
          }
        }

        if (erroCampo) {
          campoInvalido = true
          break
        }
      }

      return campoInvalido
    },

    cadastrarColaborador() {
      if (this.validarCamposFormulario(this.fields)) {
        ++this.keyFormValidacao
        this.$root.vtoast.show({
          status: 'error',
          title: 'Erro!',
          text: 'Dados Incompletos'
        })

        return
      }

      const body = {
        nome: this.fields.nome.value,
        cpf: this.fields.cpf.value,
        cidadeNaturalidade: this.fields.cidadeNaturalidade.value,
        dataNascimento: this.formatarData(this.fields.dataNascimento.value),
        profissao: this.fields.profissao.value,
        conselhoClasse: this.fields.conselhoClasse.value,
        numeroConselho: this.fields.numeroConselho.value,

        email: this.fields.email.value,
        ddd: this.fields.ddd.value,
        telefone: this.fields.telefone.value,

        bairro: this.fields.bairro.value,
        cep: this.fields.cep.value,
        logradouro: this.fields.logradouro.value,
        complemento: this.fields.complemento.value,
        cidadeEndereco: this.fields.cidadeEndereco.value,
        numero: this.fields.numero.value
      }

      this.realizandoAcao = true
      colaboradorService
        .cadastrarColaborador(body)
        .then(({ data }) => {
          sessionStorage.setItem('cadastro_colaborador', data.id)
          JSON.stringify(
            sessionStorage.setItem('estado_cadastro_colaborador', 'incompleto')
          )
          this.$emit('avancarPasso', { idColaborador: data.id })
          this.$emit('profissaoId', this.fields.profissao.value)
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
        .then(() => (this.realizandoAcao = false))
    },

    formatarData(data) {
      return `${data.slice(0, 2)}/${data.slice(2, 4)}/${data.slice(4)}`
    },

    receberProfissoes() {
      utilService.profissoes().then(({ data }) => {
        this.fields.profissao.items = data
      })
    },

    receberConselhosClasse() {
      utilService.conselhosClasse().then(({ data }) => {
        this.fields.conselhoClasse.items = data
      })
    },

    receberCidadesPorUF(ufId, fieldCidade) {
      utilService.cidadesPorUf(ufId).then(({ data }) => {
        this.fields[fieldCidade].items = data
      })
    },

    receberUFs() {
      utilService.ufs().then(({ data }) => {
        this.listaUfs = data
        this.fields.ufNaturalidade.items = data
        this.fields.ufEndereco.items = data
      })
    },

    avancarPasso() {
      if (sessionStorage.getItem('cadastro_colaborador')) {
        this.$emit('avancarPasso')
        this.$emit('profissaoId', this.fields.profissao.value)
        return
      }

      this.cadastrarColaborador()
    },

    verificarCep(cep) {
      cepService.buscarCep(cep).then(({ data }) => {
        if (data.erro) {
          this.fields.bairro.valueField = null
          this.fields.logradouro.valueField = null
          this.fields.ufEndereco.valueField = null
          this.fields.ufEndereco.value = null
          this.fields.cidadeEndereco.valueField = null
          this.fields.cidadeEndereco.value = null
          return
        }

        this.fields.bairro.valueField = data.bairro
        this.fields.logradouro.valueField = data.logradouro
        this.enderecoCep = data
      })
    }
  },

  watch: {
    'fields.cep.value': function (cep) {
      if (cep.length === 8) {
        this.verificarCep(cep)
      }
    },

    'fields.ufNaturalidade.value': function (id) {
      this.receberCidadesPorUF(id, 'cidadeNaturalidade')
    },

    'fields.conselhoClasse.value': function (value) {
      ++this.fields.numeroConselho.key

      if (value) {
        this.fields.numeroConselho.disabled = false
        this.fields.numeroConselho.rules = [
          (value) =>
            (this.fields.conselhoClasse.value && !!value) ||
            'Campo obrigatório quando o Conselho for selecionado'
        ]
      } else {
        this.fields.numeroConselho.value = null
        this.fields.numeroConselho.rules = []
        this.fields.numeroConselho.disabled = true
      }
    },

    'fields.profissao.value': function (value) {
      if (value) {
        this.fields.conselhoClasse.disabled = false
        this.fields.conselhoClasse.rules = [
          (value) =>
            (this.fields.profissao.value && !!value) ||
            'Campo obrigatório quando a Profissão for selecionado'
        ]
      } else {
        this.fields.conselhoClasse.value = null
        this.fields.conselhoClasse.rules = []
        this.fields.conselhoClasse.disabled = true
      }
    },

    'fields.ufEndereco.value': function (id) {
      this.receberCidadesPorUF(id, 'cidadeEndereco')
    },

    enderecoCep: function (valor) {
      this.listaUfs.filter((uf) => {
        if (uf.sigla === valor.uf) {
          this.fields.ufEndereco.valueField = uf.id
          this.fields.ufEndereco.value = uf.id

          utilService.cidadesPorUf(uf.id).then(({ data }) => {
            this.fields.cidadeEndereco.items = data

            this.fields.cidadeEndereco.items.filter((cidade) => {
              if (
                cidade.nome ===
                valor.localidade
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .toUpperCase()
              ) {
                this.fields.cidadeEndereco.value = cidade.id
                this.fields.cidadeEndereco.valueField = cidade.id
              }
            })
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.footer-cadastro {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 2rem 1rem 1rem;
}
</style>
