<template>
  <v-container>
    <DialogCadastroColaborador
      :scrollable="true"
      :width="950"
      :show="exibirDialogAdicionarColaborador"
      :key="keyDialogAdicionarColaborador"
      :tag="tagDialog"
      :title="'Adicionar Colaborador'"
      @close="fecharDialogAdicionarColaborador()"
      @confirm="cadastrarColaborador()"
    ></DialogCadastroColaborador>

    <CrudTable
      tabelaKey="identificador"
      :tabelaComDetalhes="true"
      :headers="headers"
      :items="colaboradores"
      :pagination="paginacao"
      :loading="carregandoAcaoGenerica"
      :keyPaginacao="keyPaginacao"
      @actualPage="page"
      @clickRow="detalharColaborador($event)"
    >
      <template v-slot:top>
        <v-toolbar flat :class="$vuetify.breakpoint.xs ? 'scroll' : ''">
          <ButtonMain
            class="mr-2"
            :primary="true"
            text="Adicionar Colaborador"
            @click.native="abrirDialogAdicionarColaborador('add')"
          />

          <v-spacer></v-spacer>

          <ButtonMain
            text="filtrar"
            :primary="true"
            @click.native="abrirDialogFiltroColaborador()"
          ></ButtonMain>

          <ButtonMain
            v-if="filtroAplicado"
            text="Limpar filtros"
            class="ml-2 warning"
            :primary="true"
            @click.native="
              limparFiltro()
              filtroAplicado = false
            "
          ></ButtonMain>

          <DialogConfirmation
            title="Filtros"
            :scrollable="true"
            :width="720"
            :show="dialogFiltroAberto"
            :key="keyDialogFiltro"
            :tag="'add'"
            @close="dialogFiltroAberto = false"
            @confirm="
              aplicarFiltroColaborador()
              dialogFiltroAberto = false
            "
          >
            <template v-slot:body>
              <v-row class="mt-3">
                <InputField
                  label="Nome"
                  :cols="6"
                  :valueField="filtro.nome"
                  @update:value="filtro.nome = $event"
                />

                <InputMaskField
                  label="Filtrar por CPF"
                  maskFormat="###.###.###-##"
                  :valueField="filtro.cpf"
                  :cols="6"
                  @update:value="filtro.cpf = $event"
                />

                <SelectField
                  label="Filtrar por Status"
                  keyValue="id"
                  keyName="descricao"
                  :items="listaStatusColaborador"
                  :cols="6"
                  :valueField="filtro.status"
                  @update:value="filtro.status = $event"
                />
              </v-row>
            </template>
          </DialogConfirmation>
        </v-toolbar>
      </template>

      <template v-slot:[`item.cpf`]="{ item }">
        <span>{{ item.cpf | VMask('###.###.###-##') }}</span>
      </template>

      <template v-slot:[`item.idStatus`]="{ item }">
        <StatusChip :color="getColor(item.idStatus)">
          {{ item.status || 'SEM VÍNCULO' }}
        </StatusChip>
      </template>

      <template v-slot:[`item.acoes`]="{ item }">
        <v-dialog v-if="item.parceiro" width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-bind="attrs"
              v-on="on"
              color="green"
              class="mr-2"
              :loading="item.idStatus !== 2 && item.atualiazandoStatus"
              :disabled="item.idStatus === 2 || item.atualiazandoStatus"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon small color="white" v-bind="attrs" v-on="on">
                    mdi-check-circle
                  </v-icon>
                </template>
                <span>Tornar Ativo</span>
              </v-tooltip>
            </v-btn>
          </template>

          <template v-slot:default="dialog">
            <v-card>
              <v-card-title color="primary">
                Tem certeza que deseja alterar o status ?
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text class="text-5 body-1 my-6">
                <div class="text--primary">
                  {{
                    `Essa ação alterará o status do vinculo entre ${item.nome}
                    e ${item.nomeParceiro} para ATIVO. Deseja continuar? `
                  }}
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn color="red darken-1" text @click="dialog.value = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="
                    atualizarStatusColaborador(item, 2)
                    dialog.value = false
                  "
                  >Confirmar</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>

        <v-dialog v-if="item.parceiro" width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-bind="attrs"
              v-on="on"
              color="red"
              class="white--text"
              :loading="item.idStatus === 2 && item.atualiazandoStatus"
              :disabled="item.idStatus !== 2 || item.atualiazandoStatus"
            >
              <v-tooltip top>
                <template small v-slot:activator="{ on, attrs }">
                  <v-icon small color="white" v-bind="attrs" v-on="on">
                    mdi-close-circle
                  </v-icon>
                </template>
                <span>Tornar Inativo</span>
              </v-tooltip>
            </v-btn>
          </template>

          <template v-slot:default="dialog">
            <v-card>
              <v-card-title color="primary">
                Tem certeza que deseja alterar o status ?
              </v-card-title>
              <v-divider></v-divider>

              <v-card-text class="text-5 body-1 my-6">
                <div class="text--primary">
                  {{
                    `Essa ação alterará o status do vinculo entre ${item.nome}
                    e ${item.nomeParceiro} para INATIVO. Deseja continuar? `
                  }}
                </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="dialog.value = false"
                  >Cancelar</v-btn
                >
                <v-btn
                  color="blue darken-1"
                  text
                  @click="
                    atualizarStatusColaborador(item, 3)
                    dialog.value = false
                  "
                  >Confirmar</v-btn
                >
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </template>
    </CrudTable>
  </v-container>
</template>

<script>
import colaboradorService from '@/services/ColaboradorService'
import utilService from '@/services/UtilService'
import cepService from '@/services/CepService'
import TitleH2 from '@/components/atoms/title/TitleH2'
import CrudTable from '@/components/molecules/tables/CrudTable'
import ButtonMain from '@/components/atoms/buttons/ButtonMain.vue'
import DialogConfirmation from '@/components/molecules/dialogs/DialogConfirmation.vue'
import DialogCadastroColaborador from '@/components/molecules/dialogs/DialogCadastroColaborador.vue'
import FormField from '@/components/atoms/forms/FormField.vue'
import StatusChip from '@/components/atoms/StatusChip.vue'
import InputMaskField from '@/components/atoms/fields/InputMaskField.vue'
import InputField from '@/components/atoms/fields/InputField.vue'
import SelectField from '@/components/atoms/fields/SelectField.vue'

export default {
  components: {
    TitleH2,
    CrudTable,
    ButtonMain,
    FormField,
    DialogConfirmation,
    DialogCadastroColaborador,
    StatusChip,
    InputMaskField,
    InputField,
    SelectField
  },

  data() {
    return {
      keyPaginacao: 0,
      keyDialogAdicionarColaborador: 0,
      keyDialogFiltro: 0,
      keyFormValidacao: 0,
      mensagemErroPadrao: 'Falha na conexão. Por favor, tente mais tarde.',
      tagDialog: '',
      dialogFiltroAberto: false,
      filtroAplicado: false,
      carregandoAcaoGenerica: false,
      exibirDialogAdicionarColaborador: false,
      colaboradores: [],
      listaUfs: [],
      listaStatusColaborador: [],
      enderecoCep: [],
      validacao: 0,
      itensPorPagina: 10,
      filtro: {
        nome: '',
        cpf: '',
        status: ''
      },
      paginacao: {
        page: 0,
        perPage: 10,
        total: 0
      },
      headers: [
        { text: 'CPF', value: 'cpf', width: 135, align: 'center', sortable: false },
        { text: 'Nome', value: 'nome', sortable: false },
        { text: 'Filial', value: 'nomeParceiro', align: 'center',  sortable: false},
        { text: 'Status', align: 'center', value: 'idStatus', sortable: false },
        { text: '', value: 'acoes', width: 150, sortable: false }
      ],
      validadores: {
        obrigatorio: 'Campo obrigatório',
        invalido: 'Campo inválido',
        numeroConselho: 'Campo obrigatório quando o Conselho for selecionado',
      },
      fields: {
        nome: {
          label: 'Nome',
          valueField: null,
          value: '',
          cols: 12,
          field: 'InputField',
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        cpf: {
          label: 'CPF',
          valueField: null,
          cols: 3,
          value: '',
          field: 'InputMaskField',
          maskFormat: '###.###.###-##',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) => value.replace(/[^\d]/g, '').length >= 11 || this.validadores.invalido,
          ]
        },
        dataNascimento: {
          label: 'Data de Nascimento',
          valueField: null,
          cols: 3,
          value: '',
          field: 'InputMaskField',
          maskFormat: '##/##/####',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) => value.replace(/[^\d]/g, '').length >= 8 || this.validadores.invalido,
          ],
        },
        ufNaturalidade: {
          label: 'UF Naturalidade',
          valueField: null,
          value: '',
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'sigla',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio],
        },
        cidadeNaturalidade: {
          label: 'Cidade Naturalidade',
          valueField: null,
          value: null,
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'nome',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio],
        },
        conselhoClasse: {
          label: 'Conselho Classe',
          valueField: null,
          value: null,
          cols: 3,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'sigla',
          clearable: true,
          rules: [],
        },
        numeroConselho: {
          label: 'Número do Conselho',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputField',
          disabled: true,
          rules: [],
          key: 19,
        },

        contatosDivisor: {
          cols: 12,
          label: 'Contatos',
          field: 'DividerMain'
        },
        email: {
          label: 'Email',
          valueField: null,
          value: '',
          cols: 7,
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) => (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).test(value) || this.validadores.invalido,
          ],
          field: 'InputField'
        },
        ddd: {
          label: 'DDD',
          valueField: null,
          cols: 2,
          value: '',

          field: 'InputMaskField',
          maskFormat: '##',
          rules: [(value) => !!value || this.validadores.obrigatorio]
        },
        telefone: {
          label: 'Telefone ',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputMaskField',
          maskFormat: '#####-####',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) => value.replace(/[^\d]/g, '').length >= 9 || this.validadores.invalido,
          ],
        },

        enderecoDivisor: {
          cols: 12,
          label: 'Endereço',
          field: 'DividerMain'
        },
        cep: {
          label: 'CEP',
          valueField: null,
          value: '',
          cols: 3,
          field: 'InputMaskField',
          maskFormat: '##.###-###',
          rules: [
            (value) => !!value || this.validadores.obrigatorio,
            (value) => value.replace(/[^\d]/g, '').length >= 8 || this.validadores.invalido,
          ],
        },
        logradouro: {
          label: 'Logradouro',
          value: '',
          valueField: null,
          cols: 6,
          rules: [(value) => !!value || this.validadores.obrigatorio],
          field: 'InputField'
        },
        numero: {
          label: 'Número',
          valueField: null,
          value: '',
          cols: 3,
          rules: [(value) => !!value || this.validadores.obrigatorio],
          field: 'InputField'
        },
        complemento: {
          label: 'Complemento',
          valueField: null,
          value: null,
          cols: 6,
          field: 'InputField'
        },
        bairro: {
          label: 'Bairro',
          valueField: null,
          value: '',
          cols: 6,
          field: 'InputField',
          rules: [(value) => !!value || this.validadores.obrigatorio],
        },
        ufEndereco: {
          label: 'UF',
          valueField: null,
          value: '',
          cols: 2,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'sigla',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio],
        },
        cidadeEndereco: {
          label: 'Cidade',
          valueField: null,
          value: '',
          cols: 4,
          field: 'SelectField',
          items: [],
          keyValue: 'id',
          keyName: 'nome',
          clearable: false,
          rules: [(value) => !!value || this.validadores.obrigatorio],
        },
        pontoReferencia: {
          label: 'Ponto de Referencia',
          value: null,
          valueField: null,
          cols: 6,
          field: 'InputField',
        },
      },
    }
  },

  created() {
    const paginacao = { page: this.paginacao.page, size: this.itensPorPagina };
    this.receberColaboradores(paginacao);
    this.receberStatusColaborador();
    this.receberUFs();
    this.receberConselhosClasse();
  },

  methods: {
    receberColaboradores(paginacao) {
      this.carregandoAcaoGenerica = true

      colaboradorService
        .colaboradores(paginacao, this.filtro)
        .then(({ data }) => {
          this.colaboradores = data.content.map((colaborador, indice) => {
            return {
              ...colaborador,
              telefone: this.setTelefone(colaborador.ddd, colaborador.telefone),
              cidade: colaborador.nomeCidadeEndereco,
              atualiazandoStatus: false,
              identificador: indice + 1,
            }
          });

          this.paginacao.total = data.totalPages
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
        .then(() => (this.carregandoAcaoGenerica = false))
    },

    receberStatusColaborador() {
      utilService.listaStatusAtivacao().then(({ data }) => this.listaStatusColaborador = data);
    },

    receberConselhosClasse() {
      utilService.conselhosClasse().then(({ data }) => {
        this.fields.conselhoClasse.items = data;
      })
    },

    receberUFs() {
      utilService.ufs().then(({ data }) => {
        this.listaUfs = data;
        this.fields.ufNaturalidade.items = data;
        this.fields.ufEndereco.items = data;
      })
    },

    receberCidadesPorUF(ufId, fieldCidade) {
      utilService.cidadesPorUf(ufId).then(({ data }) => {
        this.fields[fieldCidade].items = data
      })
    },

    validarCamposFormulario(campos) {
      let campoInvalido = false;

      for(const [key, value] of Object.entries(campos)) {
        const valorCampo = value.value;
        const regras = value.rules;
        let erroCampo = false;

        if(regras && regras.length) {
          for(const regra of regras) {
            if(
              regra(valorCampo) === this.validadores.obrigatorio
              || regra(valorCampo) === this.validadores.invalido
              || regra(valorCampo) === this.validadores.numeroConselho
            ) {
              erroCampo = true;
              break;
            }
          }
        }

        if(erroCampo) {
          campoInvalido = true;
          break;
        };
      }

      return campoInvalido;
    },

    cadastrarColaborador() {
      if (this.validarCamposFormulario(this.fields)) {
        ++this.keyFormValidacao;
        this.$root.vtoast.show({
          status: 'error',
          title: 'Erro!',
          text: 'Dados Incompletos',
        });

        return;
      }

      const body = {
        nome: this.fields.nome.value,
        cpf: this.fields.cpf.value,
        cidadeNaturalidade: this.fields.cidadeNaturalidade.value,
        dataNascimento: this.formatarData(this.fields.dataNascimento.value),
        conselhoClasse: this.fields.conselhoClasse.value,
        numeroConselho: this.fields.numeroConselho.value,

        email: this.fields.email.value,
        ddd: this.fields.ddd.value,
        telefone: this.fields.telefone.value,

        bairro: this.fields.bairro.value,
        cep: this.fields.cep.value,
        logradouro: this.fields.logradouro.value,
        complemento: this.fields.complemento.value,
        cidadeEndereco: this.fields.cidadeEndereco.value,
        numero: this.fields.numero.value,
        pontoReferencia: this.fields.pontoReferencia.value,
      }

      this.carregandoAcaoGenerica = true;
      colaboradorService.cadastrarColaborador(body)
        .then(() => {
          this.fecharDialogAdicionarColaborador();
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo
          })
        })
        .then(() => this.carregandoAcaoGenerica = false)
    },

    formatarData(data) {
      return `${data.slice(0, 2)}/${data.slice(2, 4)}/${data.slice(4)}`;
    },

    abrirDialogAdicionarColaborador(tag) {
      this.tagDialog = tag;
      this.exibirDialogAdicionarColaborador = true;
      this.keyDialogAdicionarColaborador++;

      window.onbeforeunload = function(e) {
        return "Deseja recarregar/sair esta/desta página? Dado não salvos serão perdidos";
      }

      sessionStorage.removeItem('cadastro_colaborador');
      sessionStorage.removeItem('estado_cadastro_colaborador');
    },

    fecharDialogAdicionarColaborador() {
      this.keyDialogAdicionarColaborador++;
      this.exibirDialogAdicionarColaborador = false;
      const paginacao = { page: this.paginacao.page, size: this.itensPorPagina };
      this.receberColaboradores(paginacao);
      window.onbeforeunload = () => undefined;
    },

    resetarPaginacao() {
      ++this.keyPaginacao
    },

    setTelefone(ddd, telefone) {
      if (ddd && telefone) return `(${ddd}) ${telefone}`
      if (!ddd && telefone) return `${telefone}`
      return null
    },

    getColor(status) {
      if (status === 1) return 'orange'
      if (status === 2) return 'green'
      if (status === 3) return 'red'
      return ''
    },

    abrirDialogFiltroColaborador() {
      this.filtro = { nome: '', cpf: '', status: '' }
      this.dialogFiltroAberto = true
      this.filtroAplicado = true
    },

    detalharColaborador(item) {
      this.$router.push({ name: 'DetalhesColaborador', params: { id: item.id } })
    },

    atualizarStatusColaborador(item, status) {
      item.atualiazandoStatus = true;

      colaboradorService
        .atualizarStatusColaborador(item.vinculo, { status: status })
        .then((_) => {
          this.$root.vtoast.show({
            status: 'success',
            text: 'Status atualizado.',
            title: 'SUCESSO!'
          });

          this.receberColaboradores({ page: this.paginacao.page, size: this.itensPorPagina });
        })
        .catch((erro) => {
          const mensagemErro = erro.response.data;
          this.$root.vtoast.show({
            status: 'error',
            title:  mensagemErro.titulo || this.mensagemErroPadrao,
            text: mensagemErro.mensagemUsuario || '',
          });
        })
        .then(() => item.atualiazandoStatus = false);
    },

    aplicarFiltroColaborador() {
      this.resetarPaginacao()
      this.paginacao.page = 1
      const paginacao = { page: 0, size: 10 }
      this.receberColaboradores(paginacao)
    },

    limparFiltro() {
      this.resetarPaginacao()
      this.filtro = { nome: '', cpf: '', status: '' }
      this.paginacao.page = 1
      const paginacao = { page: 0, size: this.itensPorPagina }
      this.receberColaboradores(paginacao)
    },

    cancelarFiltro() {
      this.carregandoAcaoGenerica = true
      this.filtro = { nome: '', cpf: '', status: '' }
      const paginacao = { page: this.paginacao.page, size: this.itensPorPagina }
      this.receberColaboradores(paginacao)
    },

    page(page) {
      this.paginacao.page = page - 1
      const paginacao = { page: this.paginacao.page, size: this.itensPorPagina }
      this.receberColaboradores(paginacao)
    },

    verificarCep(cep) {
      cepService.buscarCep(cep)
        .then(({ data }) => {
          if (data.erro) {
            this.fields.bairro.valueField = null;
            this.fields.logradouro.valueField = null;
            this.fields.ufEndereco.valueField = null;
            this.fields.ufEndereco.value = null;
            this.fields.cidadeEndereco.valueField = null;
            this.fields.cidadeEndereco.value = null;
            return;
          }

          this.fields.bairro.valueField = data.bairro;
          this.fields.logradouro.valueField = data.logradouro;
          this.enderecoCep = data;
        });
    },
  },

  watch: {
    'fields.cep.value': function (cep) {
      if (cep.length === 8) {
        this.verificarCep(cep)
      }
    },

    'fields.ufNaturalidade.value': function (id) {
      this.receberCidadesPorUF(id, 'cidadeNaturalidade')
    },

    'fields.conselhoClasse.value': function (value) {
      ++this.fields.numeroConselho.key;

      if(value) {
        this.fields.numeroConselho.disabled = false;
        this.fields.numeroConselho.rules = [
          (value) => this.fields.conselhoClasse.value && !!value|| 'Campo obrigatório quando o Conselho for selecionado',
        ]
      } else {
        this.fields.numeroConselho.value = null;
        this.fields.numeroConselho.rules = [];
        this.fields.numeroConselho.disabled = true;
      }
    },

    'fields.ufEndereco.value': function (id) {
      this.receberCidadesPorUF(id, 'cidadeEndereco')
    },

    enderecoCep: function (valor) {
      this.listaUfs.filter((uf) => {
        if (uf.sigla === valor.uf) {
          this.fields.ufEndereco.valueField = uf.id
          this.fields.ufEndereco.value = uf.id

          utilService.cidadesPorUf(uf.id).then(({ data }) => {
            this.fields.cidadeEndereco.items = data

            this.fields.cidadeEndereco.items.filter((cidade) => {
              if (cidade.nome === valor.localidade.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase()) {
                this.fields.cidadeEndereco.value = cidade.id
                this.fields.cidadeEndereco.valueField = cidade.id
              }
            });
          })
        }
      });
    }
  },
}
</script>

<style scoped>
.scroll {
  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
}
</style>
