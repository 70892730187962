<template>
  <div>
    <DialogConfirmation
      :persistente="true"
      :width="tagDialog === 'add' ? 850 : 500"
      :show="exibirDialogConfirmacao"
      :tag="tagDialog"
      :title="`${
        tagDialog === 'delete' ? 'Desvincular' : 'Vincular'
      } Especialização`"
      @close="fecharDialog()"
      @confirm="confirmarDialog()"
    >
      <template v-slot:body>
        <div v-if="tagDialog === 'add'">
          <v-autocomplete
            class="mt-5"
            v-model="especializacaoSelecionada"
            label="Especializações"
            item-text="descricao"
            item-value="id"
            outlined
            return-object
            no-data-text="Especialiazação não encontrada"
            :items="especializacoesNaoVinculadas"
          >
          </v-autocomplete>
        </div>

        <p class="mt-5" v-if="tagDialog === 'activate'">
          Deseja vincular a especialização
          {{ especializacaoSelecionada.descricao }} à este colaborador?
        </p>

        <p class="mt-5" v-if="tagDialog === 'delete'">
          Deseja desvincular a especialização
          {{ especializacaoSelecionada.descricao }} deste colaborador?
        </p>
      </template>
    </DialogConfirmation>

    <CrudTable
      tabelaKey="id"
      :headers="headers"
      :items="especializacoesVinculadas"
      :pagination="pagination"
      :loading="loading"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <ButtonMain
            :primary="true"
            text="Vincular Especialização"
            @click.native="abrirDialogVincularEspecializaco()"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          small
          color="green"
          class="mr-2"
          @click="
            exibirDialogConfirmacao = true
            tagDialog = 'activate'
            especializacaoSelecionada = item
          "
          :loading="!item.ativo && item.atualizandoStatus"
          :disabled="item.ativo || item.atualizandoStatus"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small color="white" v-bind="attrs" v-on="on">
                mdi-check-circle
              </v-icon>
            </template>
            <span>Vincular Especialização</span>
          </v-tooltip>
        </v-btn>
        <v-btn
          small
          color="red"
          class="white--text"
          @click="
            exibirDialogConfirmacao = true
            tagDialog = 'delete'
            especializacaoSelecionada = item
          "
          :loading="item.ativo && item.atualizandoStatus"
          :disabled="!item.ativo || item.atualizandoStatus"
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small color="white" v-bind="attrs" v-on="on">
                mdi-close-circle
              </v-icon>
            </template>
            <span>Desvincular Especialização</span>
          </v-tooltip>
        </v-btn>
      </template>

      <template v-slot:[`item.descricao`]="{ item }">
        <span :class="item.ativo ? '' : 'especializacao-desviculada'">{{
          item.descricao
        }}</span>
      </template>
    </CrudTable>

    <div v-if="cadastrandoColaborador" class="footer-cadastro">
      <ButtonMain
        class="primary mr-2"
        style="width: 10rem"
        text="Voltar"
        @click.native="voltarPasso()"
      ></ButtonMain>

      <ButtonMain
        class="primary"
        style="width: 13rem"
        text="Concluir Cadastro"
        @click.native="concluirCadastro()"
      ></ButtonMain>
    </div>

    <div
      v-if="contextoComponente !== 'area-colaboradores'"
      class="footer-pagina"
    >
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn class="ml-3 mt-5" :to="rotaDeVolta">
          <v-icon small>mdi-arrow-left-thin </v-icon>
          Voltar
        </v-btn></v-toolbar
      >
    </div>
  </div>
</template>

<script>
import colaboradorService from '@/services/ColaboradorService'
import CrudTable from '../../components/molecules/tables/CrudTable'
import ButtonMain from '../../components/atoms/buttons/ButtonMain.vue'
import DialogConfirmation from '../../components/molecules/dialogs/DialogConfirmation'

export default {
  components: {
    CrudTable,
    ButtonMain,
    DialogConfirmation
  },

  props: {
    profissaoId: { type: Number, required: false }
  },

  data() {
    return {
      rotaDeVolta: '/lista-colaboradores',
      contextoComponente: '',
      tagDialog: '',
      especializacaoSelecionada: {},
      especializacoesNaoVinculadas: [],
      especializacoesVinculadas: [],
      loading: false,
      cadastrandoColaborador: false,
      exibirDialogConfirmacao: false,

      headers: [
        { text: 'Descrição', value: 'descricao', sortable: false },
        {
          text: 'Status',
          value: 'status',
          align: 'center',
          width: 160,
          sortable: false
        },
        { text: 'Ações', value: 'actions', width: 150, sortable: false }
      ],
      pagination: {
        page: 0,
        perPage: 10,
        total: 0
      }
    }
  },

  created() {
    this.definirContextoComponente()
  },

  methods: {
    definirContextoComponente() {
      const href = location.href

      if (href.includes('/lista-colaboradores')) {
        this.contextoComponente = 'area-colaboradores'
        this.cadastrandoColaborador = true
      }

      if (href.includes('/detalhes-colaborador/')) {
        this.contextoComponente = 'detalhes-colaborador'
        this.receberEspecializacoesColaborador()
      }
    },

    receberEspecializacoesColaborador() {
      colaboradorService
        .especializacoesPorColaborador(this.gerColaboradorId())
        .then(({ data }) => {
          this.especializacoesVinculadas = data.map((item) => {
            return {
              ...item,
              status: item.ativo ? 'VINCULADA' : 'DESVINCULADA',
              atualizandoStatus: false
            }
          })
        })
    },

    fecharDialog() {
      this.exibirDialogConfirmacao = false
      this.especializacaoSelecionada = ''
      this.receberEspecializacoesColaborador()
    },

    abrirDialogVincularEspecializaco() {
      this.exibirDialogConfirmacao = true
      this.tagDialog = 'add'
      if (this.$props.profissaoId) {
        this.receberEspecializacoesNaoVinculadas({
          colaborador: this.gerColaboradorId(),
          profissao: this.$props.profissaoId
        })
      }
    },

    receberEspecializacoesNaoVinculadas(paramObj) {
      colaboradorService
        .especializacoesNaoVinculadasAoColaboradorPorProfissao(paramObj)
        .then(({ data }) => {
          this.especializacoesNaoVinculadas = data
        })
    },

    receberEspecializacoesColaborador() {
      colaboradorService
        .especializacoesPorColaborador(this.gerColaboradorId())
        .then(({ data }) => {
          this.especializacoesVinculadas = data.map((item) => {
            return {
              ...item,
              status: item.ativo ? 'VINCULADA' : 'DESVINCULADA'
            }
          })
        })
    },

    gerColaboradorId() {
      return this.$route.params.id
    },

    confirmarDialog() {
      this.exibirDialogConfirmacao = false
      const especializacao = this.especializacoesVinculadas.find(
        (esp) => esp.id === this.especializacaoSelecionada.id
      )

      if (this.tagDialog === 'add') {
        this.vincularEspecializacao()
      } else if (this.tagDialog === 'activate') {
        this.revincularEspecializacao(especializacao)
      } else if (this.tagDialog === 'delete') {
        this.desvincularEspecializacao(especializacao)
      }
    },

    vincularEspecializacao() {
      if (Object.keys(this.especializacaoSelecionada).length) {
        const body = {
          idColab: Number(this.gerColaboradorId()),
          idEsp: Number(this.especializacaoSelecionada.id)
        }

        colaboradorService
          .vincularEspecializacaoAoColaborador(body)
          .then(() => {
            this.$root.vtoast.show({
              status: 'success',
              text: 'Especialização vinculada com sucesso.',
              title: 'SUCESSO!'
            })
          })
          .catch((err) => {
            this.$root.vtoast.show({
              status: 'error',
              text: err.response?.data.mensagemUsuario,
              title: err.response?.data.titulo
            })
          })
          .then(() => {
            this.especializacaoSelecionada = {}
            this.receberEspecializacoesColaborador()
          })
      } else {
        this.$root.vtoast.show({
          status: 'error',
          title: 'Erro!',
          text: 'Nenhum item selacionado'
        })
      }
    },

    desvincularEspecializacao(especializacao) {
      especializacao.atualizandoStatus = true

      colaboradorService
        .desativarEspecializacaoAoColaborador(
          this.gerColaboradorId(),
          this.especializacaoSelecionada.idEspecializacao
        )
        .then(() => {
          this.$root.vtoast.show({
            status: 'success',
            text: 'Especialização desvinculada com sucesso.',
            title: 'SUCESSO!'
          })
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo,
            time: 60000
          })
        })
        .then(() => {
          this.especializacaoSelecionada = {}
          especializacao.atualizandoStatus = false
          this.receberEspecializacoesColaborador()
        })
    },

    revincularEspecializacao(especializacao) {
      especializacao.atualizandoStatus = true

      colaboradorService
        .ativarEspecializacaoAoColaborador(
          this.gerColaboradorId(),
          this.especializacaoSelecionada.idEspecializacao
        )
        .then(() => {
          this.$root.vtoast.show({
            status: 'success',
            text: 'Especialização vinculada com sucesso.',
            title: 'SUCESSO!'
          })
        })
        .catch((err) => {
          this.$root.vtoast.show({
            status: 'error',
            text: err.response?.data.mensagemUsuario,
            title: err.response?.data.titulo,
            time: 60000
          })
        })
        .then(() => {
          this.especializacaoSelecionada = {}
          especializacao.atualizandoStatus = false
          this.receberEspecializacoesColaborador()
        })
    },

    concluirCadastro() {
      if (
        !this.especializacoesVinculadas.length ||
        !this.especializacoesVinculadas.find((esp) => esp.ativo)
      ) {
        this.$root.vtoast.show({
          status: 'warning',
          title: 'Atenção!',
          text: 'Pelo menos um vínculo com especialização deve estar ativo.',
          time: 30000
        })

        return
      }

      sessionStorage.setItem('estado_cadastro_colaborador', 'concluido')
      this.$emit('concluirCadastro')
    },

    voltarPasso() {
      this.$emit('voltarPasso')
    },

    gerColaboradorId() {
      const colaboradorCadastradoId = sessionStorage.getItem(
        'cadastro_colaborador'
      )

      if (this.contextoComponente === 'area-colaboradores') {
        return colaboradorCadastradoId
          ? JSON.parse(colaboradorCadastradoId)
          : null
      }

      if (this.contextoComponente === 'detalhes-colaborador') {
        return this.$route.params.id
      }
    }
  }
}
</script>

<style>
.especializacao-desviculada {
  color: #9e9e9e;
  text-decoration: line-through;
}

.footer-cadastro {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 2rem 1rem 1rem;
}

.footer-pagina {
  margin-top: 2rem;
}
</style>
