var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('DialogCadastroColaborador',{key:_vm.keyDialogAdicionarColaborador,attrs:{"scrollable":true,"width":950,"show":_vm.exibirDialogAdicionarColaborador,"tag":_vm.tagDialog,"title":'Adicionar Colaborador'},on:{"close":function($event){return _vm.fecharDialogAdicionarColaborador()},"confirm":function($event){return _vm.cadastrarColaborador()}}}),_c('CrudTable',{attrs:{"tabelaKey":"identificador","tabelaComDetalhes":true,"headers":_vm.headers,"items":_vm.colaboradores,"pagination":_vm.paginacao,"loading":_vm.carregandoAcaoGenerica,"keyPaginacao":_vm.keyPaginacao},on:{"actualPage":_vm.page,"clickRow":function($event){return _vm.detalharColaborador($event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{class:_vm.$vuetify.breakpoint.xs ? 'scroll' : '',attrs:{"flat":""}},[_c('ButtonMain',{staticClass:"mr-2",attrs:{"primary":true,"text":"Adicionar Colaborador"},nativeOn:{"click":function($event){return _vm.abrirDialogAdicionarColaborador('add')}}}),_c('v-spacer'),_c('ButtonMain',{attrs:{"text":"filtrar","primary":true},nativeOn:{"click":function($event){return _vm.abrirDialogFiltroColaborador()}}}),(_vm.filtroAplicado)?_c('ButtonMain',{staticClass:"ml-2 warning",attrs:{"text":"Limpar filtros","primary":true},nativeOn:{"click":function($event){_vm.limparFiltro()
            _vm.filtroAplicado = false}}}):_vm._e(),_c('DialogConfirmation',{key:_vm.keyDialogFiltro,attrs:{"title":"Filtros","scrollable":true,"width":720,"show":_vm.dialogFiltroAberto,"tag":'add'},on:{"close":function($event){_vm.dialogFiltroAberto = false},"confirm":function($event){_vm.aplicarFiltroColaborador()
            _vm.dialogFiltroAberto = false}},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-row',{staticClass:"mt-3"},[_c('InputField',{attrs:{"label":"Nome","cols":6,"valueField":_vm.filtro.nome},on:{"update:value":function($event){_vm.filtro.nome = $event}}}),_c('InputMaskField',{attrs:{"label":"Filtrar por CPF","maskFormat":"###.###.###-##","valueField":_vm.filtro.cpf,"cols":6},on:{"update:value":function($event){_vm.filtro.cpf = $event}}}),_c('SelectField',{attrs:{"label":"Filtrar por Status","keyValue":"id","keyName":"descricao","items":_vm.listaStatusColaborador,"cols":6,"valueField":_vm.filtro.status},on:{"update:value":function($event){_vm.filtro.status = $event}}})],1)]},proxy:true}])})],1)]},proxy:true},{key:"item.cpf",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("VMask")(item.cpf,'###.###.###-##')))])]}},{key:"item.idStatus",fn:function(ref){
            var item = ref.item;
return [_c('StatusChip',{attrs:{"color":_vm.getColor(item.idStatus)}},[_vm._v(" "+_vm._s(item.status || 'SEM VÍNCULO')+" ")])]}},{key:"item.acoes",fn:function(ref){
            var item = ref.item;
return [(item.parceiro)?_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"green","loading":item.idStatus !== 2 && item.atualiazandoStatus,"disabled":item.idStatus === 2 || item.atualiazandoStatus}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle ")])]}}],null,true)},[_c('span',[_vm._v("Tornar Ativo")])])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{attrs:{"color":"primary"}},[_vm._v(" Tem certeza que deseja alterar o status ? ")]),_c('v-divider'),_c('v-card-text',{staticClass:"text-5 body-1 my-6"},[_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(("Essa ação alterará o status do vinculo entre " + (item.nome) + " e " + (item.nomeParceiro) + " para ATIVO. Deseja continuar? "))+" ")])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.atualizarStatusColaborador(item, 2)
                  dialog.value = false}}},[_vm._v("Confirmar")])],1)],1)]}}],null,true)}):_vm._e(),(item.parceiro)?_c('v-dialog',{attrs:{"width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"small":"","color":"red","loading":item.idStatus === 2 && item.atualiazandoStatus,"disabled":item.idStatus !== 2 || item.atualiazandoStatus}},'v-btn',attrs,false),on),[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"white"}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle ")])]}}],null,true)},[_c('span',[_vm._v("Tornar Inativo")])])],1)]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-card-title',{attrs:{"color":"primary"}},[_vm._v(" Tem certeza que deseja alterar o status ? ")]),_c('v-divider'),_c('v-card-text',{staticClass:"text-5 body-1 my-6"},[_c('div',{staticClass:"text--primary"},[_vm._v(" "+_vm._s(("Essa ação alterará o status do vinculo entre " + (item.nome) + " e " + (item.nomeParceiro) + " para INATIVO. Deseja continuar? "))+" ")])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.atualizarStatusColaborador(item, 3)
                  dialog.value = false}}},[_vm._v("Confirmar")])],1)],1)]}}],null,true)}):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }