<template>
  <div>
    <v-stepper v-model="passoAtual" elevation="0">
      <v-stepper-header style="box-shadow: 0 0 0">
        <v-stepper-step
          v-for="(item, index) in itensPassos"
          :key="index"
          :complete="passoAtual > item.passo"
          :step="item.passo"
        >
          {{ item.header }}
        </v-stepper-step>

        <v-divider></v-divider>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content
          v-for="(item, index) in itensPassos"
          :key="index"
          :step="item.passo"
          style="padding: 0"
        >
          <v-container>
            <component
              class="mt-5"
              :is="item.component"
              @voltarPasso="voltarPasso($event, item)"
              @avancarPasso="avancarPasso($event, item)"
              @concluirCadastro="concluirCadastro()"
              @profissaoId="storeProfissaoId($event)"
              :profissao-id="profissaoId"
            />
          </v-container>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
import ButtonMain from '../../components/atoms/buttons/ButtonMain.vue'
import PerfilColaborador from '../../pages/colaborador/PerfilCadastroColaborador.vue'
import EspecializacoesColaborador from '../../pages/colaborador/EspecializacoesColaborador.vue'

export default {
  components: {
    PerfilColaborador,
    EspecializacoesColaborador,
    ButtonMain
  },

  data() {
    return {
      passoAtual: 1,
      profissaoId: null,
      itensPassos: [
        {
          passo: 1,
          header: 'Dados',
          content: 'tab-perfil-colaborador',
          component: 'PerfilColaborador',
          desabilitada: false,
          realizandoAcao: false
        },
        {
          passo: 2,
          header: 'Especializações',
          content: 'tab-especializacoes-colaborador',
          component: 'EspecializacoesColaborador',
          desabilitada: true,
          realizandoAcao: false
        }
      ]
    }
  },

  methods: {
    voltarPasso(evento, item) {
      if (this.passoAtual > 1) {
        this.passoAtual = item.passo - 1
      }
    },

    avancarPasso(evento, item) {
      if (this.passoAtual < this.itensPassos.length) {
        this.passoAtual = item.passo + 1
      }
    },

    concluirCadastro() {
      this.$emit('concluirCadastro')
    },

    storeProfissaoId(emmitedId) {
      this.profissaoId = emmitedId
    }
  }
}
</script>

<style scoped>
.footer-dialog {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 1rem 3rem;
}
</style>
